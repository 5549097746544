// src/components/About.js
import React from 'react';
import { Container, Typography } from '@mui/material';

const About = () => (
  <Container style={{
      'background-color': 'white',
      'height': '100%',
      'padding-top': '20px',
      'overflow-y': 'auto'
      }}>
    <Typography variant="h4" component="h1" gutterBottom>
      Ramsham Hi-Fi
    </Typography>
    <Typography variant="body1">
        <p>
            We're an independent reggae sound system based within the south west, UK.
        </p>
        <p>
            Comprised of a small community of selectors, music fans & close friends, we started our journey putting on
            reggae parties for the local community. Overtime we developed the crew & the sound, and in midst of the 2020 COVID
            lockdown decided to take the journey to its next chapter. <br/><br/>It was time to build the Ramsham sound system.
        </p>
        <p>
            We strive for authenticity, quality & respect, both in our sound & our sessions.
            We operate with the highest level of attention
            to detail, with everyone in the dance in mind.
        </p>
        <p>
            The music we play is more than just beats & chords. Reggae music has a soul, a voice and a message that
            is rooted deep in the hearts of the people. It is our primary goal to present and provide that message with
            the up most respect.
        </p>
    </Typography>
  </Container>
);

export default About;


// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { AppBar } from '@mui/material';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Gallery from './components/Photos';
import Event from './components/Events';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import HomePageImage from "./img/home.jpeg";
import ToolbarMobileReady from "./components/ToolbarMobileReady";

function App() {
  let backgroundImageStyle;
  backgroundImageStyle = {
    backgroundImage: `url(${HomePageImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '93vh',
    maxWidth: '100% !important',
    'background-blend-mode': 'lighten',
    'background-color': 'rgba(255,255,255,0.3)'
  };

  return (
    <ThemeProvider theme={theme} margin={0}>
      <Router>
        <AppBar position="static">
          <ToolbarMobileReady/>
        </AppBar>
        <div style={backgroundImageStyle}>
          <Routes>
            <Route exact path="/" element={<Home/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/contact" element={<Contact/>}/>
            <Route path="/photos" element={<Gallery />} />
            <Route path="/events" element={<Event />} />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;


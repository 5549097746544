// src/components/Home.js
import React from 'react';
import { Container, Typography } from '@mui/material';
import one from '../img/20240518.jpeg';
import two from '../img/MR ROCK STEADY.jpg';
import PhotoAlbum from "react-photo-album";

const Events = () => {
    const photos = [
        {
            src: one,
            width: 1200,
            height: 1800,
            srcSet: [
                { src: one, width: 1200, height:  1800}
            ],
        },
        {
            src: two,
            width: 1200,
            height: 1800,
            srcSet: [
                { src: two, width: 1200, height: 1800 }
            ],
        }
        ];
    return(
        <Container
                   style={{
                       'background-color': 'white',
                       'height': '100%',
                       'padding-top': '20px',
                       'overflow-y': 'auto'
                   }}>

            <Typography variant="h4" component="h1" gutterBottom>
                Upcoming Events
            </Typography>
            <Typography variant="body1">
                <ul>
                    <li>
                        <a href={'https://www.facebook.com/events/1020107029834913'}>
                            Friday 26th July 2024 - Stack Up Campout - Okehampton, Devon
                        </a>
                    </li>
                    <li>Saturday 24th August 2024 - Devon Reggae & Dub Alliance - Newton Abbot, Devon</li>
                </ul>
            </Typography>
            <br/>
            <Typography variant="h4" component="h1" gutterBottom>
                Past Events
            </Typography>
            <Typography variant="body2">
                    <PhotoAlbum
                        layout="columns"
                        photos={photos}
                        columns={(containerWidth) => {
                            if (containerWidth < 500) return 1;
                            if (containerWidth < 900) return 2;
                            return 2;
                        }}/>
            </Typography>


        </Container>
    );
};

export default Events;

import Box from "@mui/material/Box";
import Title from "../img/title.png";
import {
    Button, Drawer,
    IconButton, List, ListItem,
    Toolbar,
    useMediaQuery
} from "@mui/material";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import MenuIcon from '@mui/icons-material/Menu';

function ToolbarMobileReady() {
    const isMobile = useMediaQuery('(max-width:1200px)');

    // State to manage drawer open/close
    const [drawerOpen, setDrawerOpen] = useState(false);

    // Toggle drawer state
    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    return (
        <Toolbar style={{
            display: 'flex',
            justifyContent: 'space-between', // This ensures the toolbar contents are spaced out
        }}>
            {isMobile ? (
                // Render Accordion for mobile view
                <>
                    <Box component="img" src={Title} alt="Logo" sx={{ height: 35 }} ></Box>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{
                        float: 'right'
                        }}
                        onClick={toggleDrawer(true)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Drawer
                        anchor="right"
                        open={drawerOpen}
                        onClose={toggleDrawer(false)}
                    >
                        <List>
                            <ListItem button>
                                <Button
                                    color="inherit"
                                    component={Link}
                                    to="/"
                                    onClick={toggleDrawer(false)}>Home</Button>
                            </ListItem>
                            <ListItem button>
                                <Button
                                    color="inherit"
                                    component={Link}
                                    to="/about"
                                    onClick={toggleDrawer(false)}>About</Button>
                            </ListItem>
                            <ListItem button>
                                <Button
                                    color="inherit"
                                    component={Link}
                                    to="/contact"
                                    onClick={toggleDrawer(false)}>Contact</Button>
                            </ListItem>
                            <ListItem button>
                                <Button
                                    color="inherit"
                                    component={Link}
                                    to="/photos"
                                    onClick={toggleDrawer(false)}>Photos</Button>
                            </ListItem>
                            <ListItem button>
                                <Button
                                    color="inherit"
                                    component={Link}
                                    to="/events"
                                    onClick={toggleDrawer(false)}>Events</Button>
                            </ListItem>
                        </List>
                    </Drawer>
                </>
            ) : (
<>
                    <Box component="img" src={Title} alt="Logo" sx={{ height: 40, mr: 2 }} ></Box>

                    <iframe title={'Mixcloud'} width="50%" height="60"
                            src="https://player-widget.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&light=1&autoplay=1&feed=%2Framsham_hifi%2Framsham-hi-fi-dub-steppas-mix%2F"
                            frameBorder="0" allow="autoplay"></iframe>


                    <Button color="inherit" component={Link} to="/">Home</Button>
                    <Button color="inherit" component={Link} to="/about">About</Button>
                    <Button color="inherit" component={Link} to="/contact">Contact</Button>
                    <Button color="inherit" component={Link} to="/photos">Photos</Button>
                    <Button color="inherit" component={Link} to="/events">Events</Button>
                </>
            )}
        </Toolbar>
    );
}

export default ToolbarMobileReady;
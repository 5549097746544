// src/components/Contact.js
import React from 'react';
import { SocialIcon } from 'react-social-icons/component'
import { Container, Typography } from '@mui/material';

import 'react-social-icons/facebook';
import 'react-social-icons/instagram';
import 'react-social-icons/youtube';
import 'react-social-icons/email';

const Contact = () => (
  <Container
      style={{
          'background-color': 'white',
          'height': '100%',
          'padding-top': '20px',
          'overflow-y': 'auto'
      }}>
    <Typography variant="h4" component="h1" gutterBottom>
      Contact Us
    </Typography>
      <Typography>
          Planning an event and need amazing sound? We've got you covered!
          Whether you need just the sound equipment or a full event organization team,
          we're here to help make your event unforgettable.
          Drop us a line and let’s chat about how we can bring your vision to life.
      </Typography>
    <Typography variant="body1">
      <br/>For bookings, queries or anything else get in touch, we'd love to hear from you!<br/><br/>
        <SocialIcon network='email' style={{margin: '0 10px 0 0'}} url='mailto:ramshamhifi@gmail.com'/>

        <a href='mailto:ramshamhifi@gmail.com'>
            Email us - ramshamhifi@gmail.com
        </a>

    </Typography>
      <br/>
      <Typography>
          You can also find us at<br/>
          <SocialIcon
              style={{margin: '0 10px 0 0'}}
              url="https://www.instagram.com/ramsham_hifi/" />
          <SocialIcon
              style={{margin: '0 10px 0 0'}}
              url='https://www.facebook.com/ramsham.hifi'/>
          <SocialIcon
              style={{margin: '0 10px 0 0'}}
              url='https://www.youtube.com/@ramsham_hifi'/>
      </Typography>
  </Container>
);

export default Contact;

